import axios from 'axios';
// import errorNotifications from '$lib/axios-interceptors/error-notifications-interceptor.js';
import { injectHeader, storeToken } from '$lib/axios-interceptors/token-support-interceptor.js';
import { getAppDomain } from '$lib/domain.js';

axios.create({
  baseURL: getAppDomain()
});

injectHeader(axios);
storeToken(axios);
// errorNotifications(axios);

const externalAxios = axios.create();

// if (VUE_APP_ENV === 'development') {
//   apiMock(api);
// }

/**
 * @function checkInitialStep
 * @param {String} idLead
 */
export const fetchStepsData = idLead => axios.get(`/api/lead/${idLead}/steps`);

/**
 * @typedef {Object} ContinueStepBody
 * @property {string} nextStep
 * @property {string} currentStep
 * @property {Object} data
 */

/**
 * @function checkInitialStep
 * @param {ContinueStepBody} body
 */
export const continueStep = (idLead, step, body) =>
  axios.post(`/api/lead/${idLead}/steps/${step}`, body);

/**
 * @function downloadOffer
 * @param {string} idLead
 * */
export const downloadOffer = (uid, offerId) =>
  axios.get(`/api/files/downloads/leads/${uid}/offers/${offerId}`);

/**
 * @function uploadInvoice
 * @param {string} idLead
 * @param {FormData} formData
 * */
export const uploadInvoice = (idLead, formData) =>
  axios.post(`/api/files/uploads/invoices/leads/${idLead}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });

/**
 * @function sendPhone
 * @param {string} idLead
 * @param {string} phone
 * */
export const sendPhone = (idLead, phone) => axios.post(`/api/lead/${idLead}/phone`, { phone });

/**
 * @function registerBack
 * @param {string} idLead
 * @param {string} step
 */
export const registerBack = (idLead, step) => axios.post(`/api/lead/${idLead}/back`, { step });

export const getFormInitialData = () => axios.get('/api/initial-data');

export const getLocalityByProvince = provinceId =>
  axios.get(`/api/provincia/${provinceId}/municipios`);

export const uploadFile = (uid, formData) =>
  axios.post(`/api/files/uploads/leads/${uid}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });

export const getBicSwiftByIban = iban => axios.get(`api/iban/${iban}/bic-swift`);

export const changeElectricalPower = (leadId, potencias) =>
  axios.put(`api/lead/${leadId}/power-consumption`, potencias);

export const downloadContractPDF = uid =>
  axios.get(`api/files/downloads/leads/${uid}/contract`, { responseType: 'blob' });

export const getProvinciaByCode = code => axios.get(`api/provincia/${code}`);
export const getMunicipio = (provinciaCode, municipioName) =>
  axios.get(`api/provincia/${provinciaCode}/municipios/${municipioName}`);

export const getTipoViaByName = name => axios.get(`api/tipo-via/${name}`);

export const getRandomPerson = () =>
  externalAxios.get('https://api.generadordni.es/v2/profiles/person?results=1');
export const getRandomDNI = () =>
  externalAxios.get('https://api.generadordni.es/v2/doi/nif?results=2');
export const getRandomCIF = () =>
  externalAxios.get('https://api.generadordni.es/v2/doi/cif?results=1');
export const getRandomCUPS = () =>
  externalAxios.get('https://api.generadordni.es/v2/misc/cups?results=1');
