//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BottomSheet from '$components/ui/bottom-sheet.vue';
import ContentSheetAyuda from './content-sheet-ayuda.vue';

export default {
  components: {
    BottomSheet,
    ContentSheetAyuda
  },
  data: () => ({
    showBottomSheet: false
  }),
  watch: {
    showBottomSheet(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      }

      if (!value) {
        document.body.style.overflow = '';
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    handleContentUpdate() {
      this.$nextTick(this.$refs.sheet.storeComponentHeight);
    },
    onWindowResize() {
      this.$nextTick(this.$refs.sheet.storeComponentHeight);
    },
    handleSendPhone(...params) {
      this.$emit('send-phone', ...params);
    }
  }
};
