//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightSheet from '$components/ui/right-sheet.vue';
import ContentSheetAyuda from './content-sheet-ayuda.vue';

export default {
  components: {
    RightSheet,
    ContentSheetAyuda
  },
  data: () => ({
    showRightSheet: false
  }),
  watch: {
    showBottomSheet(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      }

      if (!value) {
        document.body.style.overflow = '';
      }
    }
  },
  methods: {
    handleSendPhone(...params) {
      this.$emit('send-phone', ...params);
    }
  }
};
