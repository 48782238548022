//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    colorHeader: {
      type: String,
      default: 'blue',
      validator: v => ['blue', 'white'].includes(v)
    },
    disableClose: {
      type: Boolean
    }
  },
  data: () => ({
    display: false,
    headerHeight: null,
    elementHeight: null
  }),
  computed: {
    mainClassName() {
      const className = { show: this.value };

      className[this.colorHeader] = true;

      return className;
    },
    transformStyle() {
      const { elementHeight, value, display } = this;
      const style = {};

      if (!display) {
        style.opacity = 0;
        style.pointerEvents = 'none';
      }

      return {
        ...style,
        transform: `translateY(${value ? 0 : elementHeight - 50}px)`
      };
    }
  },
  mounted() {
    this.storeComponentHeight();
  },
  methods: {
    handleHeaderClick() {
      if (!this.disableClose) this.$emit('input', !this.value);
    },
    async storeComponentHeight() {
      this.elementHeight = this.$el.offsetHeight;
      this.headerHeight = this.$refs.header.offsetHeight;

      /** Si esta cerrado y es el primer render, se espera a que este bien posicionado
       * para evitar un salto en el renderizado. */
      if (!this.display && !this.value) {
        this.waitUntilPositioned(() => {
          this.display = true;
        });
      }

      /** Si por defecto esta mostrandose, se muestra directamente */
      if (!this.display && this.value) {
        this.display = true;
      }
    },
    /**
     * Este metodo comprueba si el header a llegado al final de la pagina para lanzar el callback.
     */
    waitUntilPositioned(cb) {
      const check = () =>
        window.requestAnimationFrame(() => {
          if (
            this.$refs.header &&
            this.$refs.header.getBoundingClientRect().bottom - window.innerHeight >= -5
          ) {
            cb();
          } else {
            setTimeout(() => check(), 25);
          }
        });

      check();
    }
  }
};
